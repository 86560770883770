import React from "react"
import { Link as GLink } from "gatsby"
import { Flex, Box, Text, Heading, Card, Link } from "theme-ui"
import Divider from "@components/Divider"
import MemphisPattern from "@components/MemphisPattern"
import Avatar from "../Shared/Avatar"
import ExpertLogo from "./ExpertLogo"

const styles = {
  card: {
    position: `relative`,
  },
  wrapper: {
    flexDirection: [`column`, `row`],
    position: `relative`,
    zIndex: 3,
  },
  wrapperConten: {
    flexDirection: [`column`, `column`],
    position: `relative`,
    zIndex: 3,
  },
  avatarColumn: {
    flexBasis: `1/3`,
  },
  infoColumn: {
    flexBasis: `2/3`,
  },
  innerBox: {
    flexBasis: `1/2`,
    flexGrow: 1,
    px: [0, 3],
    mt: [3, 0],
    textAlign: ['center', 'left'],
  },
  innerBoxDetail: {
    flexBasis: `1/2`,
    flexGrow: 1,
    px: [0, 0],
    mt: [3, 0],
  },
  subheader: {
    color: `omegaDark`,
  },
  name: {
    textAlign: [`center`, `left`],
    mt: [3, 0],
    px: 3,
  },
  bio: {
    textAlign: [`center`, `left`],
  },
  socialList: {
    a: {
      m: 0,
    },
  },
  link: {
    position: `absolute`,
    top: 10,
    right: 10,
    zIndex: 3,
  },
  pattern: {
    borderRadius: `lg`,
  },
  gradient: {
    size: `full`,
    borderRadius: `lg`,
    position: `absolute`,
    left: 0,
    top: 0,
    zIndex: 2,
    background: [
      `linear-gradient(0deg, white 20%, transparent 80%)`,
      `linear-gradient(270deg, white 20%, transparent 100%)`,
    ],
  },
}

const Subheader = ({ children }) => (
  <Heading variant="h4" sx={styles.subheader}>
    {children}
  </Heading>
)

const AuthorAvatar = ({ name, avatar, slug }) =>
  avatar ? (
    <Box>
      <Link as={GLink} to={slug} aria-label={name}>
        <Avatar avatar={avatar} />
      </Link>
    </Box>
  ) : null

const AuthorName = ({ name, slug }) => (
  <Box sx={styles.name}>
    <Heading variant="h3">
      <Link as={GLink} to={slug}>
        {name}
      </Link>
    </Heading>
  </Box>
)

const Description = ({ details, description, short_description }) =>
  details ? <Text>{description}</Text> : <Text>{short_description}</Text>

const AuthorBio = ({
  role,
  content_role,
  short_description,
  description,
  details,
}) => (
  <Box sx={styles.bio}>
    <Subheader>{role}</Subheader>
    {!content_role === "" ? <Subheader>{content_role}</Subheader> : null}
    <Description
      details={details}
      description={description}
      short_description={short_description}
    />
  </Box>
)

const AuthorContacts = ({ contact, details }) =>
  contact ? (
    <Box sx={!details ? styles.innerBox : styles.innerBoxDetail}>
      {!details ? <Subheader>Contact</Subheader> : <Divider space={2} />}
      <Text>{contact.email}</Text>
      <Text>{contact.phone_number}</Text>
    </Box>
  ) : null

const AuthorExpanded = ({ author, details }) => {
  if (!author) return null

  return (
    <Card variant="paper" sx={styles.card}>
      <Flex sx={styles.wrapper}>
        <Box sx={styles.avatarColumn}>
          <AuthorAvatar {...author} />
          <Divider space={3} />
          <ExpertLogo {...author} />
        </Box>
        <Box sx={styles.infoColumn}>
          <AuthorName {...author} />
          <Flex sx={details ? styles.wrapperConten : styles.wrapper}>
            <Box sx={styles.innerBox}>
              <AuthorBio {...author} details={details} />
            </Box>
            <Box sx={styles.innerBox}>
              <Flex>
                <AuthorContacts {...author} details={details} />
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Box sx={styles.gradient} />
      <MemphisPattern sx={styles.pattern} />
    </Card>
  )
}

export default AuthorExpanded
