import React from "react"
import { Box } from "theme-ui"

//Base size to keep all layers aligned easier
const bs = x => `${x * 0.35}rem`

const styles = {
  wrapper: {
    position: `relative`,
    zIndex: 2,
    textAlign: `center`,
    mb: bs(3),
  },
  circle: ({ width }) => ({
    width: [bs(30), bs(30)],
    height: `full`,
    maxWidth: width,
    borderRadius: `full`,
    position: `absolute`,
    transform: `translate(-50%)  scale(0.98)`,
    left: `50%`,
    top: bs(3),
    bg: `alpha`,
  }),
  arc: ({ width }) => ({
    width: [bs(30), bs(30)],
    height: `full`,
    maxWidth: width,
    borderRadius: `full`,
    position: `absolute`,
    zIndex: 2,
    left: `50%`,
    transform: `translate(-50%)`,
    mt: bs(-1),
    ml: bs(-2),
    boxShadow: t => `
			${bs(2)}
			${bs(4)}
			${t.colors.omegaLight}
		`,
  }),
  imageWrapper: ({ width }) => ({
    width: [bs(30), bs(30)],
    maxWidth: width,
    position: `relative`,
    mx: `auto`,
    "> div": {
      borderRadius: `0 0 9999px 9999px`,
    },
  }),
  imageStyle: {
    borderRadius: `30%`,
  },
}

const Avatar = ({ expert_logo, width = "150" }) => {
  if (!expert_logo) return null

  return (
    <Box sx={styles.wrapper}>
      <Box>
        <Box sx={styles.imageWrapper({ width })}>
          <img
            style={styles.imageStyle}
            src={expert_logo}
            width={width}
            alt=""
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Avatar
